export const INVERTED_DIMENSION_ANGLES = [90, 270];
export const ANIMATIONS = {
  next: 'next',
  previous: 'previous',
};

export const CURSOR = {
  zoomIn: 'zoom-in',
  zoomOut: 'zoom-out',
  grabbing: 'grabbing',
};

export const ROTATION_ZOOM_CALCULATIONS = {
  0: (x, y) => ({
    x,
    y,
  }),
  270: (x, y) => ({
    x: 100 - y,
    y: x,
  }),
  180: (x, y) => ({
    x: 100 - x,
    y: 100 - y,
  }),
  90: (x, y) => ({
    x: y,
    y: 100 - x,
  }),
};

export const MOVEMENT_BY_ROTATION = {
  0: (x, y) => ({
    x,
    y,
  }),
  270: (x, y) => ({
    x: -y,
    y: x,
  }),
  180: (x, y) => ({
    x: -x,
    y: -y,
  }),
  90: (x, y) => ({
    x: y,
    y: -x,
  }),
};
