var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "gallery",
      staticClass: "GalleryComponent d-flex flex-column",
      attrs: { "data-test-id": "gallery" },
    },
    [
      _vm.showingImage.label
        ? _c("label", { staticClass: "d-block emobg-label-1 mb-1" }, [
            _vm._v(" " + _vm._s(_vm.showingImage.label) + " "),
          ])
        : _vm._e(),
      _c(
        "div",
        {
          ref: "imageWrapper",
          staticClass:
            "GalleryComponent__image-wrapper flex-fill overflow-hidden position-relative p-2 emobg-border-1 emobg-border-color-ground emobg-border-radius-small emobg-background-color-ground-lighter",
        },
        [
          _c(
            "Transition",
            { attrs: { name: `GalleryComponent--${_vm.animation}` } },
            [
              _vm.showingImage.src
                ? _c(
                    "div",
                    {
                      key: _vm.indexToShow,
                      staticClass:
                        "GalleryComponent__inner-image-wrapper position-absolute d-flex flex-column align-items-center justify-content-center w-100",
                    },
                    [
                      _vm.hasPreview
                        ? _c(
                            "div",
                            _vm._g(
                              {
                                ref: "image",
                                staticClass:
                                  "GalleryComponent__image position-absolute",
                                style: {
                                  backgroundImage: `url('${_vm.showingImage.src}')`,
                                  transform: `rotate(${_vm.rotation}deg)`,
                                  cursor: _vm.cursorImageStyles,
                                  width:
                                    _vm.imageWrapperDimensions.width &&
                                    `${_vm.imageWrapperDimensions.width}px`,
                                  height:
                                    _vm.imageWrapperDimensions.height &&
                                    `${_vm.imageWrapperDimensions.height}px`,
                                  backgroundSize: _vm.zoom.isZoomed
                                    ? _vm.backgroundSizeWhenZoomed
                                    : "contain",
                                  backgroundPosition: _vm.zoom.isZoomed
                                    ? `${_vm.zoom.zoomedInX}% ${_vm.zoom.zoomedInY}%`
                                    : undefined,
                                },
                                attrs: {
                                  "data-test-id": `image-${_vm.indexToShow}`,
                                },
                              },
                              _vm.onImageListeners
                            )
                          )
                        : _vm._e(),
                      _vm.zoomable && !_vm.zoom.isZoomed
                        ? _c("div", {
                            ref: "auxImage",
                            staticClass: "position-absolute",
                            style: {
                              width:
                                _vm.imageDimensions.width &&
                                `${_vm.imageDimensions.width}px`,
                              height:
                                _vm.imageDimensions.height &&
                                `${_vm.imageDimensions.height}px`,
                              cursor: _vm.CURSOR.zoomIn,
                            },
                            on: { click: _vm.zoomImage },
                          })
                        : _vm._e(),
                      !_vm.hasPreview
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "d-flex flex-column align-items-center justify-content-center emobg-background-color-white h-100 w-75",
                            },
                            [
                              _c("p", { staticClass: "mb-3" }, [
                                _vm._v(" Preview not available "),
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "emobg-link-primary emobg-body-2",
                                  on: {
                                    click: function ($event) {
                                      _vm.downloadFile({
                                        url: _vm.showingImage.src,
                                        name:
                                          _vm.showingImage.label ||
                                          _vm.getImageName(
                                            _vm.showingImage.src
                                          ),
                                      })
                                    },
                                  },
                                },
                                [
                                  _vm._v(" Open "),
                                  _c("ui-icon", {
                                    staticClass: "ml-1",
                                    attrs: {
                                      icon: _vm.ICONS.externalUrl,
                                      size: _vm.ICONS_SIZES.xSmall,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]
                  )
                : _vm._e(),
            ]
          ),
        ],
        1
      ),
      !_vm.zoom.isZoomed
        ? _c(
            "div",
            {
              staticClass:
                "GalleryComponent__actions d-flex align-items-center justify-content-center mt-2",
            },
            [
              _vm.customNavigation
                ? _c(
                    "div",
                    {
                      staticClass:
                        "flex-grow-1 d-flex align-items-center justify-content-center mr-n5",
                    },
                    [_vm.customNavigation ? _vm._t("default") : _vm._e()],
                    2
                  )
                : _c(
                    "div",
                    {
                      staticClass:
                        "flex-grow-1 d-flex align-items-center justify-content-center mr-n5",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "emobg-body-2 emobg-color-ink-light mr-2",
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.images.length ? _vm.indexToShow + 1 : 0
                              ) +
                              " of " +
                              _vm._s(_vm.images.length) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "ui-button",
                        {
                          staticClass: "mr-2",
                          attrs: {
                            face: _vm.FACES.outline,
                            color: _vm.GRAYSCALE.inkLight,
                            square: "",
                            disabled: _vm.isPreviousDisabled,
                            "data-test-id": "previous-button",
                          },
                          on: { clickbutton: _vm.previous },
                        },
                        [
                          _c("ui-icon", {
                            staticClass: "d-flex flex-column align-center",
                            attrs: {
                              icon: _vm.ICONS.bold.left,
                              size: _vm.ICONS_SIZES.medium,
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "ui-button",
                        {
                          attrs: {
                            face: _vm.FACES.outline,
                            color: _vm.GRAYSCALE.inkLight,
                            disabled: _vm.isNextDisabled,
                            square: "",
                            "data-test-id": "next-button",
                          },
                          on: { clickbutton: _vm.next },
                        },
                        [
                          _c("ui-icon", {
                            staticClass: "d-flex flex-column align-center",
                            attrs: {
                              icon: _vm.ICONS.bold.right,
                              size: _vm.ICONS_SIZES.medium,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
              _c(
                "div",
                { staticClass: "flex-grow-0" },
                [
                  _c(
                    "ui-tooltip",
                    {
                      attrs: {
                        placement: _vm.PLACEMENTS.left,
                        tooltip: "Rotation is preview only",
                      },
                    },
                    [
                      _c(
                        "ui-button",
                        {
                          attrs: {
                            face: _vm.FACES.outline,
                            color: _vm.GRAYSCALE.inkLight,
                            square: "",
                            disabled: !_vm.images.length || !_vm.hasPreview,
                            "data-test-id": "rotate-button",
                          },
                          on: { clickbutton: _vm.rotate },
                        },
                        [
                          _c("ui-icon", {
                            staticClass: "d-flex flex-column align-center",
                            attrs: {
                              icon: _vm.ICONS.rotate,
                              size: _vm.ICONS_SIZES.medium,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }