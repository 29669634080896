var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "CommentsList", attrs: { "data-test-id": "comments-list" } },
    [
      _c(
        "div",
        {
          staticClass: "d-flex justify-content-between align-items-center pb-2",
        },
        [
          _vm._t("header", function () {
            return [_c("h1", [_vm._v(" Comments ")])]
          }),
          _c(
            "ui-button",
            {
              staticClass: "wmin-initial",
              attrs: { disabled: _vm.disabled },
              on: {
                clickbutton: function ($event) {
                  _vm.isModalVisible = true
                },
              },
            },
            [_vm._v(" Add comment ")]
          ),
        ],
        2
      ),
      _c(
        "MuiCard",
        { staticClass: "d-flex flex-column" },
        [
          _vm.isLoading
            ? _c("CommentSkeleton")
            : _c(
                "div",
                [
                  !_vm.comments.length
                    ? _c("EmptyStateComponent", {
                        staticClass: "py-3 px-2",
                        attrs: { title: "You haven't added any comments yet" },
                      })
                    : _c(
                        "div",
                        {
                          staticClass:
                            "CommentsList__comments my-n3 mr-n3 pr-3",
                        },
                        _vm._l(_vm.comments, function (comment, index) {
                          return _c("CommentComponent", {
                            key: comment.uuid,
                            class: [
                              "py-4",
                              {
                                "emobg-border-top-1 emobg-border-color-ground-light py-4":
                                  index !== 0,
                              },
                            ],
                            attrs: {
                              comment: comment,
                              callback: _vm.callback,
                              disabled: _vm.disabled,
                            },
                            on: {
                              commentToEdit: _vm.setEditedComment,
                              commentToDelete: _vm.setDeletedComment,
                            },
                          })
                        }),
                        1
                      ),
                ],
                1
              ),
        ],
        1
      ),
      _vm.isModalVisible
        ? _c("CommentForm", {
            attrs: {
              comment: _vm.commentToEdit,
              entity: _vm.entity,
              "entity-uuid": _vm.entityUuid,
              callback: _vm.callback,
            },
            on: { closeModal: _vm.closeModal, "modal-closed": _vm.closeModal },
          })
        : _vm._e(),
      _vm.isDeleteModalVisible
        ? _c("DeleteCommentForm", {
            attrs: { comment: _vm.commentToEdit, callback: _vm.callback },
            on: { closeModal: _vm.closeModal, "modal-closed": _vm.closeModal },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }