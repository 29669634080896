import get from 'lodash/get';
import omit from 'lodash/omit';
import {
  createCustomStore,
  withScopes,
} from '@emobg/web-utils';
import { backOffice } from '@emobg/web-api-client';
import API_VERSIONS from '@/constants/apiVersions.const';
import DEFAULT_API_ERROR from '@/constants/defaultError';
import { clearErrors, toastError } from '@/utils';

export const COMPANY_SCOPES = {
  config: 'config',
  company: 'company',
  costAllocation: 'costAllocation',
  categories: 'categories',
  employeePolicy: 'employeePolicy',
  deleteEmployeePolicy: 'deleteEmployeePolicy',
  employeePolicyForm: 'employeePolicyForm',
  paymentMethodStatus: 'paymentMethodStatus',
  paymentMethodForm: 'paymentMethodForm',
  statusForm: 'statusForm',
  certificateOfRegistrationURL: 'certificateOfRegistrationURL',
  generalSettingsForm: 'generalSettingsForm',
  fiscalInformationForm: 'fiscalInformationForm',
  companyProfileForm: 'companyProfileForm',
  companyTariffs: 'companyTariffs',
  companyTariffForm: 'companyTariffForm',
  deleteCompanyTariff: 'deleteCompanyTariff',
  employeeTariffForm: 'employeeTariffForm',
  updateCompanyConfig: 'updateCompanyConfig',
  companyCreate: 'companyCreate',
  availableCompanyTariffs: 'availableCompanyTariffs',
  availableCompanyTariffPeriods: 'availableCompanyTariffPeriods',
};

const COMPANY_SCOPES_TOASTED = omit(COMPANY_SCOPES, [
  'employeePolicy',
  'paymentMethodForm',
  'statusForm',
  'generalSettingsForm',
  'fiscalInformationForm',
  'companyProfileForm',
  'legacyCompanyTariffForm',
  'employeeTariffForm',
  'companyCreate',
  'availableCompanyTariffs',
]);

const companyModule = createCustomStore(({ runAsyncFlow }) => withScopes(COMPANY_SCOPES, {
  state: {
    company: {
      data: null,
    },
    config: {
      data: null,
    },
    companyProfileForm: {
      data: null,
    },
    employeePolicy: {
      data: null,
    },
    employeePolicyForm: {
      data: null,
    },
    paymentMethodForm: {
      data: null,
    },
    certificateOfRegistrationURL: {
      data: null,
    },
    companyTariffs: {
      data: null,
    },
    companyTariffForm: {
      data: null,
    },
    companyCreate: {
      data: null,
    },
  },
  mutations: {
    clearErrors,
    setError(state, payload) {
      toastError.bind(this)(state, payload, COMPANY_SCOPES_TOASTED);
    },
  },
  actions: {
    getCompanyDetails({ commit }, { companyUuid, version = API_VERSIONS.v3 }) {
      return runAsyncFlow(commit, {
        request: backOffice.company.getCompanyDetails,
        params: [companyUuid, version],
        scope: COMPANY_SCOPES.company,
      });
    },
    async getCompanyConfig({ commit, state, rootState }) {
      const company = get(state, 'company.data');
      if (company) {
        const companyOperatorUuid = get(state, 'company.data.dedicatedFleetCsOperatorUuid') || get(state, 'company.data.csOperator.uuid');
        const activeOperator = get(rootState, 'App.UserAccount.operators.active', {});
        if (activeOperator.uuid === companyOperatorUuid) {
          const activeOperatorConfig = get(rootState, 'App.UserAccount.operators.configuration', {});
          commit('setData', {
            scope: COMPANY_SCOPES.config,
            value: activeOperatorConfig,
          });
        } else {
          try {
            const operatorConfiguration = await backOffice.userCompany.getOperatorsConfig(companyOperatorUuid);
            commit('setData', {
              scope: COMPANY_SCOPES.config,
              value: operatorConfiguration.configuration,
            });
          } catch (error) {
            commit('setError', {
              scope: COMPANY_SCOPES.config,
              value: get(error, 'response.data', DEFAULT_API_ERROR),
            });
          }
        }
      }
    },
    putCompanyConfig({ commit }, { companyUuid, config }) {
      return runAsyncFlow(commit, {
        request: backOffice.company.putCompanyConfig,
        params: [companyUuid, config],
        scope: COMPANY_SCOPES.updateCompanyConfig,
      });
    },
    putOpenCategory({ commit }, { dedicatedCategoryUuid, openCategoryUuid }) {
      return runAsyncFlow(commit, {
        request: backOffice.categories.putOpenCategory,
        params: [dedicatedCategoryUuid, openCategoryUuid],
        scope: COMPANY_SCOPES.categories,
      });
    },
    getEmployeePolicy({ commit }, companyId) {
      return runAsyncFlow(commit, {
        request: backOffice.employees.getEmployeePolicy,
        params: [companyId],
        scope: COMPANY_SCOPES.employeePolicy,
      });
    },
    deleteEmployeePolicy({ commit }, { companyId, statementId }) {
      return runAsyncFlow(commit, {
        request: backOffice.employees.deleteEmployeePolicy,
        params: [companyId, statementId],
        scope: COMPANY_SCOPES.deleteEmployeePolicy,
      });
    },
    putEmployeePolicy({ commit }, { companyId, statementId, payload }) {
      return runAsyncFlow(commit, {
        request: backOffice.employees.putEmployeePolicy,
        params: [companyId, statementId, payload],
        scope: COMPANY_SCOPES.employeePolicyForm,
      });
    },
    postEmployeePolicy({ commit }, { companyId, payload }) {
      return runAsyncFlow(commit, {
        request: backOffice.employees.postEmployeePolicy,
        params: [companyId, payload],
        scope: COMPANY_SCOPES.employeePolicyForm,
      });
    },
    activateCompanyPaymentMethod({ commit }, { companyUuid, paymentMethodUuid }) {
      return runAsyncFlow(commit, {
        request: backOffice.paymentMethods.patchActivatePaymentMenthod,
        params: [companyUuid, paymentMethodUuid],
        scope: COMPANY_SCOPES.paymentMethodStatus,
      });
    },
    deactivateCompanyPaymentMethod({ commit }, { companyUuid, paymentMethodUuid }) {
      return runAsyncFlow(commit, {
        request: backOffice.paymentMethods.patchDeactivatePaymentMenthod,
        params: [companyUuid, paymentMethodUuid],
        scope: COMPANY_SCOPES.paymentMethodStatus,
      });
    },
    postCompanyPaymentMethod({ commit }, { companyId, payload }) {
      return runAsyncFlow(commit, {
        request: backOffice.paymentMethods.postAddPaymentMethod,
        params: [companyId, payload],
        scope: COMPANY_SCOPES.paymentMethodForm,
      });
    },
    postAddSepaPaymentMethod({ commit }, { payload }) {
      return runAsyncFlow(commit, {
        request: backOffice.paymentMethods.postAddSepaPaymentMethod,
        params: [payload],
        scope: COMPANY_SCOPES.paymentMethodForm,
      });
    },
    putCompanyStatus({ commit }, { companyUuid, payload }) {
      return runAsyncFlow(commit, {
        request: backOffice.company.putCompanyStatus,
        params: [companyUuid, payload],
        scope: COMPANY_SCOPES.statusForm,
      });
    },
    putCompanyProfile({ commit }, { companyUuid, payload }) {
      return runAsyncFlow(commit, {
        request: backOffice.company.putCompanyProfile,
        params: [companyUuid, payload],
        scope: COMPANY_SCOPES.companyProfileForm,
      });
    },
    getDownloadCOR({ commit }, { companyUuid }) {
      return runAsyncFlow(commit, {
        request: backOffice.company.getDownloadCOR,
        params: [companyUuid],
        scope: COMPANY_SCOPES.certificateOfRegistrationURL,
      });
    },
    putGeneralSettings({ commit }, { companyUuid, payload }) {
      return runAsyncFlow(commit, {
        request: backOffice.company.putCompanySettings,
        params: [companyUuid, payload],
        scope: COMPANY_SCOPES.generalSettingsForm,
      });
    },
    putFiscalInformation({ commit }, { companyUuid, payload }) {
      return runAsyncFlow(commit, {
        request: backOffice.company.putCompanyFiscalInformation,
        params: [companyUuid, payload],
        scope: COMPANY_SCOPES.fiscalInformationForm,
      });
    },
    getCompanyTariffs({ commit }, { companyUuid }) {
      return runAsyncFlow(commit, {
        request: backOffice.pricingCompanies.getCompanyTariffs,
        params: [companyUuid],
        scope: COMPANY_SCOPES.companyTariffs,
      });
    },
    putCompanyTariff({ commit }, { companyTariffUuid, payload }) {
      return runAsyncFlow(commit, {
        request: backOffice.pricingCompanies.putCompanyTariff,
        params: [companyTariffUuid, payload],
        scope: COMPANY_SCOPES.companyTariffForm,
      });
    },
    postCompanyTariff({ commit }, { companyUuid, payload }) {
      return runAsyncFlow(commit, {
        request: backOffice.pricingCompanies.postCompanyTariff,
        params: [companyUuid, payload],
        scope: COMPANY_SCOPES.companyTariffForm,
      });
    },
    postLegacyCompanyTariff({ commit }, { companyId, payload }) {
      return runAsyncFlow(commit, {
        request: backOffice.tariffs.postLegacyCompanyTariff,
        params: [companyId, payload],
        scope: COMPANY_SCOPES.companyTariffForm,
      });
    },
    deleteCompanyTariff({ commit }, { companyTariffUuid }) {
      return runAsyncFlow(commit, {
        request: backOffice.pricingCompanies.deleteCompanyTariff,
        params: [companyTariffUuid],
        scope: COMPANY_SCOPES.deleteCompanyTariff,
      });
    },
    putCompanyEmployeePersonalTariffs({ commit }, { companyUuid, payload }) {
      return runAsyncFlow(commit, {
        request: backOffice.pricingCompanies.putCompanyEmployeesPersonalTariffs,
        params: [companyUuid, payload],
        scope: COMPANY_SCOPES.employeeTariffForm,
      });
    },
    postCompanyCreate({ commit }, { company }) {
      return runAsyncFlow(commit, {
        request: backOffice.company.postCompanyCreate,
        params: [company],
        scope: COMPANY_SCOPES.companyCreate,
      });
    },
    getAvailableCompanyTariffs({ commit }, { companyUuid, payload }) {
      return runAsyncFlow(commit, {
        request: backOffice.pricingCompanies.getAvailableCompanyTariffs,
        params: [companyUuid, payload],
        scope: COMPANY_SCOPES.availableCompanyTariffs,
      });
    },
    getAvailableCompanyTariffPeriods({ commit }, { tariffUuid, data }) {
      return runAsyncFlow(commit, {
        request: backOffice.tariffs.getAvailableTariffPeriods,
        params: [tariffUuid, data],
        scope: COMPANY_SCOPES.availableCompanyTariffPeriods,
      });
    },
  },
}));

export default companyModule;
