<script>
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import includes from 'lodash/includes';
import inRange from 'lodash/inRange';
import throttle from 'lodash/throttle';
import { DELAY, getImageName, isImage } from '@emobg/web-utils';
import { downloadFile } from '@/utils';
import {
  ANIMATIONS,
  CURSOR,
  INVERTED_DIMENSION_ANGLES,
  MOVEMENT_BY_ROTATION,
  ROTATION_ZOOM_CALCULATIONS,
} from './const/GalleryComponents.const';

export default {
  name: 'GalleryComponent',
  props: {
    /**
     * [
     *  {
     *    label: 'label of image',
     *    src: 'source of image',
     *  }
     * ]
     */
    images: {
      type: Array,
      default: () => [],
    },
    startIndex: {
      type: Number,
      default: 0,
    },
    customNavigation: {
      type: Boolean,
      default: false,
    },
    zoomable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      indexToShow: null,
      rotation: 0,
      wrapperDimensions: {
        width: null,
        height: null,
      },
      animation: ANIMATIONS.next,
      hasPreview: true,
      imageDimensions: {
        width: 0,
        height: 0,
      },
      zoom: {
        isZoomed: false,
        zoomedInX: 50,
        zoomedInY: 50,

        isMouseDown: false,
        isMouseMoved: false,
      },
    };
  },
  computed: {
    showingImage() {
      return get(this.images, `[${this.indexToShow}]`, {});
    },
    isNextDisabled() {
      return !this.images.length || this.indexToShow === this.images.length - 1;
    },
    isPreviousDisabled() {
      return this.indexToShow === 0;
    },
    isInvertedDimensions() {
      return includes(INVERTED_DIMENSION_ANGLES, this.rotation);
    },
    imageWrapperDimensions() {
      if (this.isInvertedDimensions) {
        return {
          width: this.wrapperDimensions.height,
          height: this.wrapperDimensions.width,
        };
      }

      return {
        width: this.wrapperDimensions.width,
        height: this.wrapperDimensions.height,
      };
    },
    cursorImageStyles() {
      if (this.zoomable && this.zoom.isZoomed) {
        return this.zoom.isMouseMoved
          ? CURSOR.grabbing
          : CURSOR.zoomOut;
      }
      return '';
    },
    onImageListeners() {
      if (!this.zoomable) {
        return {};
      }

      const listeners = {
        mousedown: this.onMouseDownImage,
        mouseup: this.onMouseUpImage,
        mouseleave: this.onMouseUpImage,
      };

      if (this.zoom.isZoomed) {
        listeners.mousemove = this.onMouseMoveImage;
      }
      return listeners;
    },
    backgroundSizeWhenZoomed() {
      const { width: wrapperWidth, height: wrapperHeight } = this.wrapperDimensions;
      const { width: imgWidth, height: imgHeight } = this.imageDimensions;

      const widthResized = wrapperWidth * 1.2;
      const heightResized = wrapperHeight * 2;
      const textToReplace = '{value}';
      const textSizeForWidth = `${textToReplace}px auto`;
      const textSizeForHeight = `auto ${textToReplace}px`;
      const isWidthBigger = imgWidth > imgHeight;

      if (this.isInvertedDimensions) {
        return isWidthBigger
          ? textSizeForWidth.replace(textToReplace, heightResized)
          : textSizeForHeight.replace(textToReplace, widthResized);
      }
      return isWidthBigger
        ? textSizeForHeight.replace(textToReplace, heightResized)
        : textSizeForWidth.replace(textToReplace, widthResized);
    },
  },
  watch: {
    startIndex(value) {
      this.animation = value > this.indexToShow ? ANIMATIONS.next : ANIMATIONS.previous;
      this.indexToShow = value;
    },
    async indexToShow() {
      this.rotation = 0;
      this.hasPreview = await isImage(this.showingImage.src);
    },
  },
  created() {
    this.CURSOR = CURSOR;
    this.indexToShow = inRange(this.startIndex, 0, this.images.length) ? this.startIndex : 0;
    this.debouncedReSetWrapperDimensions = debounce(this.setWrapperDimensions, DELAY.tiny);
    this.throttledMouseMove = throttle(this.onMouseMoveImage, 2);

    this.$watch(
      vm => [
        vm.wrapperDimensions,
        vm.indexToShow,
        vm.rotation,
      ],
      async () => {
        await this.setImageDimensions();
      },
    );
  },
  async mounted() {
    this.hasPreview = await isImage(this.showingImage.src);
    this.debouncedReSetWrapperDimensions();
    window.addEventListener('resize', this.debouncedReSetWrapperDimensions);
    const resizeObserver = new ResizeObserver(this.debouncedReSetWrapperDimensions);
    resizeObserver.observe(this.$refs.gallery);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.debouncedReSetWrapperDimensions);
  },
  methods: {
    downloadFile,
    getImageName,
    next() {
      if (this.indexToShow === this.images.length - 1) {
        return;
      }
      this.animation = ANIMATIONS.next;
      this.indexToShow += 1;
    },
    previous() {
      if (!this.indexToShow) {
        return;
      }
      this.animation = ANIMATIONS.previous;
      this.indexToShow -= 1;
    },
    rotate() {
      this.rotation = !this.rotation ? 270 : this.rotation - 90;
    },
    setWrapperDimensions() {
      const { clientWidth, clientHeight } = this.getImageWrapperDimensions();
      this.wrapperDimensions = {
        width: clientWidth - 16,
        height: clientHeight - 16,
      };
    },
    getImageWrapperDimensions() {
      const { clientWidth, clientHeight } = this.$refs.imageWrapper;
      return {
        clientWidth,
        clientHeight,
      };
    },
    setImageDimensions() {
      if (this.images.length) {
        const image = new Image();
        image.src = this.images[this.indexToShow].src;
        image.onload = () => {
          const { width: imageWidth, height: imageHeight } = image;
          const width = this.isInvertedDimensions ? imageHeight : imageWidth;
          const height = this.isInvertedDimensions ? imageWidth : imageHeight;
          const { width: wrapperWidth, height: wrapperHeight } = this.wrapperDimensions;
          const isHorizontalImage = width > height;
          const imgWidth = isHorizontalImage ? wrapperWidth : (width / height) * wrapperHeight;
          const imgHeight = isHorizontalImage ? (height / width) * wrapperWidth : wrapperHeight;

          this.imageDimensions = {
            width: imgWidth,
            height: imgHeight,
          };
        };
      }
    },
    zoomImage($event) {
      this.zoom.isZoomed = !this.zoom.isZoomed;
      const { clientWidth: width, clientHeight: height } = $event.target;
      const xPerCent = ($event.offsetX * 100) / width;
      const yPerCent = ($event.offsetY * 100) / height;
      const calculateZoomByRotation = ROTATION_ZOOM_CALCULATIONS[this.rotation];
      const { x, y } = calculateZoomByRotation(xPerCent, yPerCent);
      this.zoom.zoomedInX = x;
      this.zoom.zoomedInY = y;
    },
    onMouseDownImage() {
      this.zoom.isMouseDown = true;
    },
    onMouseUpImage() {
      const {
        isMouseDown,
        isMouseMoved,
      } = this.zoom;

      if (isMouseDown && !isMouseMoved) {
        this.zoom.isZoomed = false;
      }

      if (isMouseDown && isMouseMoved) {
        this.zoom.isMouseMoved = false;
      }

      this.zoom.isMouseDown = false;
    },
    onMouseMoveImage($event) {
      if (this.zoom.isMouseDown) {
        this.zoom.isMouseMoved = true;
        const {
          movementX,
          movementY,
        } = $event;

        const {
          x: movementXByRotation,
          y: movementYByRotation,
        } = MOVEMENT_BY_ROTATION[this.rotation](movementX, movementY);

        const { width, height } = this.imageDimensions;
        const imgWidth = this.isInvertedDimensions ? height : width;
        const imgHeight = this.isInvertedDimensions ? width : height;

        const isWidthBigger = imgWidth > imgHeight;
        const aspectRatioY = (isWidthBigger ? 1 : imgWidth / imgHeight) * 0.3;
        const aspectRatioX = (!isWidthBigger ? 1 : imgHeight / imgWidth) * 0.3;

        let newZoomX = this.zoom.zoomedInX + -(movementXByRotation * aspectRatioX);
        newZoomX = newZoomX > 100 ? 100 : newZoomX;
        newZoomX = newZoomX < 0 ? 0 : newZoomX;

        let newZoomY = this.zoom.zoomedInY + -(movementYByRotation * aspectRatioY);
        newZoomY = newZoomY > 100 ? 100 : newZoomY;
        newZoomY = newZoomY < 0 ? 0 : newZoomY;

        this.zoom.zoomedInX = newZoomX;
        this.zoom.zoomedInY = newZoomY;
      }
    },
  },
};
</script>

<template>
  <div
    ref="gallery"
    class="GalleryComponent d-flex flex-column"
    data-test-id="gallery"
  >
    <label
      v-if="showingImage.label"
      class="d-block emobg-label-1 mb-1"
    >
      {{ showingImage.label }}
    </label>
    <div
      ref="imageWrapper"
      class="GalleryComponent__image-wrapper flex-fill overflow-hidden position-relative p-2
        emobg-border-1 emobg-border-color-ground emobg-border-radius-small emobg-background-color-ground-lighter"
    >
      <Transition :name="`GalleryComponent--${animation}`">
        <div
          v-if="showingImage.src"
          :key="indexToShow"
          class="GalleryComponent__inner-image-wrapper position-absolute d-flex flex-column align-items-center justify-content-center w-100"
        >
          <div
            v-if="hasPreview"
            ref="image"
            :style="{
              backgroundImage: `url('${showingImage.src}')`,
              transform: `rotate(${rotation}deg)`,
              cursor: cursorImageStyles,
              width: imageWrapperDimensions.width && `${imageWrapperDimensions.width}px`,
              height: imageWrapperDimensions.height && `${imageWrapperDimensions.height}px`,
              backgroundSize: zoom.isZoomed ? backgroundSizeWhenZoomed : 'contain',
              backgroundPosition: zoom.isZoomed ? `${zoom.zoomedInX}% ${zoom.zoomedInY}%` : undefined,
            }"
            :data-test-id="`image-${indexToShow}`"
            class="GalleryComponent__image position-absolute"
            v-on="onImageListeners"
          />
          <div
            v-if="zoomable && !zoom.isZoomed"
            ref="auxImage"
            :style="{
              width: imageDimensions.width && `${imageDimensions.width}px`,
              height: imageDimensions.height && `${imageDimensions.height}px`,
              cursor: CURSOR.zoomIn
            }"
            class="position-absolute"
            @click="zoomImage"
          />
          <div
            v-if="!hasPreview"
            class="d-flex flex-column align-items-center justify-content-center emobg-background-color-white h-100 w-75"
          >
            <p class="mb-3">
              Preview not available
            </p>
            <div
              class="emobg-link-primary emobg-body-2"
              @click="downloadFile({ url: showingImage.src, name: showingImage.label || getImageName(showingImage.src) })"
            >
              Open
              <ui-icon
                :icon="ICONS.externalUrl"
                :size="ICONS_SIZES.xSmall"
                class="ml-1"
              />
            </div>
          </div>
        </div>
      </Transition>
    </div>
    <div
      v-if="!zoom.isZoomed"
      class="GalleryComponent__actions d-flex align-items-center justify-content-center mt-2"
    >
      <div
        v-if="customNavigation"
        class="flex-grow-1 d-flex align-items-center justify-content-center mr-n5"
      >
        <slot v-if="customNavigation" />
      </div>
      <div
        v-else
        class="flex-grow-1 d-flex align-items-center justify-content-center mr-n5"
      >
        <div class="emobg-body-2 emobg-color-ink-light mr-2">
          {{ images.length ? indexToShow + 1 : 0 }}
          of
          {{ images.length }}
        </div>
        <ui-button
          :face="FACES.outline"
          :color="GRAYSCALE.inkLight"
          square
          :disabled="isPreviousDisabled"
          class="mr-2"
          data-test-id="previous-button"
          @clickbutton="previous"
        >
          <ui-icon
            :icon="ICONS.bold.left"
            :size="ICONS_SIZES.medium"
            class="d-flex flex-column align-center"
          />
        </ui-button>
        <ui-button
          :face="FACES.outline"
          :color="GRAYSCALE.inkLight"
          :disabled="isNextDisabled"
          square
          data-test-id="next-button"
          @clickbutton="next"
        >
          <ui-icon
            :icon="ICONS.bold.right"
            :size="ICONS_SIZES.medium"
            class="d-flex flex-column align-center"
          />
        </ui-button>
      </div>
      <div class="flex-grow-0">
        <ui-tooltip
          :placement="PLACEMENTS.left"
          tooltip="Rotation is preview only"
        >
          <ui-button
            :face="FACES.outline"
            :color="GRAYSCALE.inkLight"
            square
            :disabled="!images.length || !hasPreview"
            data-test-id="rotate-button"
            @clickbutton="rotate"
          >
            <ui-icon
              :icon="ICONS.rotate"
              :size="ICONS_SIZES.medium"
              class="d-flex flex-column align-center"
            />
          </ui-button>
        </ui-tooltip>
      </div>
    </div>
  </div>
</template>
