var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "table",
      staticClass:
        "Table d-flex flex-column emobg-background-color-white emobg-border-1 emobg-border-color-ground-light overflow-x-auto",
    },
    [
      _c(
        "header",
        {
          staticClass: "Table__header Table__row emobg-label-1",
          style: _vm.rowOverflowMargin && {
            marginRight: `-${_vm.rowOverflowMargin}px`,
          },
        },
        [
          _vm._l(_vm.nonCollapsedSchema, function (schemaItem, index) {
            return _c(
              "div",
              {
                key: index,
                class: [
                  "Table__cell emobg-background-color-ground-lighter emobg-border-bottom-4 emobg-border-color-ground-light",
                  {
                    "Table__cell--sticky": _vm.isStickyColumn(
                      schemaItem,
                      index
                    ),
                  },
                ],
                style: _vm.getCellStyles(schemaItem),
              },
              [
                _c("div", { staticClass: "Table__cell--inner text-ellipsis" }, [
                  _vm._v(" " + _vm._s(schemaItem.header) + " "),
                ]),
              ]
            )
          }),
          _vm.rowActions.length || _vm.collapsedSchema.length
            ? _c(
                "div",
                {
                  ref: "headerActionCell",
                  staticClass:
                    "Table__cell Table__cell--actions emobg-background-color-ground-lighter emobg-border-bottom-4 emobg-border-color-ground-light",
                },
                [
                  _vm.rowActions.length
                    ? _c("div", { staticStyle: { width: "40px" } })
                    : _vm._e(),
                  _vm.collapsedSchema.length
                    ? _c(
                        "ui-button",
                        {
                          attrs: {
                            color: _vm.GRAYSCALE.inkLight,
                            face: _vm.FACES.text,
                            square: "",
                          },
                          on: { clickbutton: _vm.toggleAll },
                        },
                        [
                          _c("ui-icon", {
                            attrs: {
                              icon: _vm.globalToggle
                                ? _vm.ICONS.arrowUp
                                : _vm.ICONS.arrowDown,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
      _vm._l(_vm.data, function (row, indexData) {
        return _c(
          "div",
          {
            key: indexData,
            class: [
              "Table__row emobg-body-1 emobg-border-bottom-1 emobg-border-color-ground-light",
              {
                "emobg-border-bottom-1 emobg-border-color-ground-light":
                  indexData < _vm.data.length - 1,
              },
            ],
            style: _vm.rowOverflowMargin && {
              marginRight: `-${_vm.rowOverflowMargin}px`,
            },
          },
          [
            _c(
              "div",
              { staticClass: "Table__cells-non-collapsed" },
              [
                _vm._l(_vm.nonCollapsedSchema, function (schemaItem, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      ref: "cell",
                      refInFor: true,
                      class: [
                        "Table__cell",
                        {
                          "Table__cell--sticky": _vm.isStickyColumn(
                            schemaItem,
                            index
                          ),
                        },
                      ],
                      style: _vm.getCellStyles(schemaItem),
                      attrs: { "row-index": indexData, "cell-index": index },
                    },
                    [
                      _c("TableContentWrapperComponent", {
                        attrs: {
                          "schema-item": schemaItem,
                          "data-item": row,
                          "has-tooltip":
                            _vm.rowOptions[indexData].cellOptions[index]
                              .hasTooltip,
                          "data-index": indexData,
                        },
                      }),
                    ],
                    1
                  )
                }),
                _vm.rowActions.length || _vm.collapsedSchema.length
                  ? _c(
                      "div",
                      { staticClass: "Table__cell Table__cell--actions" },
                      [
                        _c(
                          "ui-dropdown",
                          {
                            class: [
                              { invisible: !_vm.filterActions(row).length },
                              "cursor-pointer",
                            ],
                          },
                          [
                            _c("ui-icon", {
                              attrs: {
                                slot: "trigger",
                                icon: _vm.ICONS.optionsHorizontalFull,
                                color: _vm.GRAYSCALE.inkLight,
                              },
                              slot: "trigger",
                            }),
                            _c("ui-dropdown-actions", {
                              attrs: { slot: "content" },
                              domProps: {
                                actions: _vm.map(
                                  _vm.filterActions(row),
                                  (rowAction) => ({
                                    ...rowAction,
                                    action: () =>
                                      rowAction.action(row, indexData),
                                  })
                                ),
                              },
                              slot: "content",
                            }),
                          ],
                          1
                        ),
                        _vm.collapsedSchema.length
                          ? _c(
                              "ui-button",
                              {
                                attrs: {
                                  color: _vm.GRAYSCALE.inkLight,
                                  face: _vm.FACES.text,
                                  square: "",
                                },
                                on: {
                                  clickbutton: function ($event) {
                                    _vm.rowOptions[indexData].isCollapseOpened =
                                      !_vm.rowOptions[indexData]
                                        .isCollapseOpened
                                  },
                                },
                              },
                              [
                                _c("ui-icon", {
                                  attrs: {
                                    icon: _vm.rowOptions[indexData]
                                      .isCollapseOpened
                                      ? _vm.ICONS.arrowUp
                                      : _vm.ICONS.arrowDown,
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              2
            ),
            _vm.collapsedSchema.length
              ? _c(
                  "div",
                  { staticClass: "Table__cells-collapsed" },
                  [
                    _c(
                      "MuiCollapse",
                      {
                        staticClass:
                          "Table__collapse-section overflow-hidden d-flex flex-wrap",
                        attrs: {
                          open: _vm.rowOptions[indexData].isCollapseOpened,
                        },
                      },
                      _vm._l(
                        _vm.collapsedSchema,
                        function (collapsedSchemaItem, collapsedSchemaIndex) {
                          return _c(
                            "div",
                            {
                              key: collapsedSchemaIndex,
                              staticClass: "Table__cell-collapsed",
                            },
                            [
                              _c("TableContentWrapperComponent", {
                                attrs: {
                                  "schema-item": collapsedSchemaItem,
                                  "data-item": row,
                                  "data-index": indexData,
                                  "is-collapsed": "",
                                },
                              }),
                            ],
                            1
                          )
                        }
                      ),
                      0
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]
        )
      }),
      !_vm.size(_vm.data)
        ? _c(
            "div",
            {
              staticClass:
                "Table__no-results flex-fill d-flex flex-column justify-content-center align-items-center py-4 px-3 text-center emobg-background-color-ground-lightest",
              style: _vm.rowOverflowMargin && {
                marginRight: `-${_vm.rowOverflowMargin}px`,
              },
            },
            [
              _vm._t("empty", function () {
                return [_vm._v(" " + _vm._s(_vm.emptyLabel) + " ")]
              }),
            ],
            2
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }