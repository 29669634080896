<script>
import { mapMutations } from 'vuex';
import { MuiCard } from '@emobg/vue-base';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import EmptyStateComponent from '@/components/EmptyState/EmptyStateComponent';
import CommentComponent from './components/CommentComponent';
import CommentSkeleton from './components/CommentSkeleton';
import CommentForm from '../Modals/CommentForm';
import DeleteCommentForm from '../Modals/DeleteCommentForm';

export default {
  name: 'CommentsList',
  components: {
    EmptyStateComponent,
    DeleteCommentForm,
    CommentComponent,
    CommentSkeleton,
    CommentForm,
    MuiCard,
  },
  props: {
    entity: {
      type: String,
      required: true,
    },
    entityUuid: {
      type: String,
      required: true,
    },
    comments: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    callback: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      commentToEdit: null,
      isModalVisible: false,
      isDeleteModalVisible: false,
    };
  },
  methods: {
    setEditedComment(comment) {
      this.commentToEdit = comment;
      this.isModalVisible = true;
    },
    setDeletedComment(comment) {
      this.commentToEdit = comment;
      this.isDeleteModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
      this.isDeleteModalVisible = false;
      this.commentToEdit = null;
      this.clearErrors();
    },
    ...mapMutations(DOMAINS_MODEL.app.comments, [
      'clearErrors',
    ]),
  },
};
</script>
<template>
  <div
    class="CommentsList"
    data-test-id="comments-list"
  >
    <div class="d-flex justify-content-between align-items-center pb-2">
      <slot name="header">
        <h1>
          Comments
        </h1>
      </slot>
      <ui-button
        class="wmin-initial"
        @clickbutton="isModalVisible = true"
      >
        Add comment
      </ui-button>
    </div>

    <MuiCard class="d-flex flex-column">
      <CommentSkeleton v-if="isLoading" />
      <div v-else>
        <EmptyStateComponent
          v-if="!comments.length"
          title="You haven't added any comments yet"
          class="py-3 px-2"
        />
        <div
          v-else
          class="CommentsList__comments my-n3 mr-n3 pr-3"
        >
          <CommentComponent
            v-for="(comment, index) in comments"
            :key="comment.uuid"
            :comment="comment"
            :callback="callback"
            :class="[
              'py-4',
              { 'emobg-border-top-1 emobg-border-color-ground-light py-4': index !== 0 },
            ]"
            @commentToEdit="setEditedComment"
            @commentToDelete="setDeletedComment"
          />
        </div>
      </div>
    </MuiCard>

    <CommentForm
      v-if="isModalVisible"
      :comment="commentToEdit"
      :entity="entity"
      :entity-uuid="entityUuid"
      :callback="callback"
      @closeModal="closeModal"
      @modal-closed="closeModal"
    />
    <DeleteCommentForm
      v-if="isDeleteModalVisible"
      :comment="commentToEdit"
      :callback="callback"
      @closeModal="closeModal"
      @modal-closed="closeModal"
    />
  </div>
</template>
