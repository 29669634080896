<script>
import filter from 'lodash/filter';
import size from 'lodash/size';
import each from 'lodash/each';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import first from 'lodash/first';
import { getValue, isArrayValue } from '@emobg/web-utils';

export default {
  name: 'BreadcrumbsComponent',
  props: {
    breadcrumbConfig: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    routeParams() {
      const { params } = this.$route;
      return params;
    },
    pageUrl() {
      return getValue(this.$route, 'fullPath');
    },
  },
  watch: {
    $route() {
      this.getBreadcrumbs();
    },
  },
  methods: {
    /**
     * Returns a list of links with text (Pages). Based on internal vue router properties.
     * @returns Array of objects with the following properties:
     * `path` (url)
     * `label` (text shown)
     */
    getBreadcrumbs() {
      const breadcrumbs = [];

      if (isEmpty(this.breadcrumbConfig)) {
        each(this.$route.matched, route => {
          const label = getValue(route, 'meta.label');

          if (isArrayValue(label)) {
            each(label, ({ label: routeLabel, name: routeName }) => {
              let link = '';

              if (routeName) {
                const routeFound = this.$router.resolve({ name: routeName });
                link = routeFound.href;
              }

              breadcrumbs.push({
                label: routeLabel,
                link,
              });
            });
          } else {
            breadcrumbs.push(this.interpolate(route));
          }
        });
      } else {
        const pathConfig = find(this.breadcrumbConfig, (config) => config.urlRegexp.test(this.pageUrl));

        if (pathConfig) {
          this.buildBreadcrumbs(pathConfig, breadcrumbs);
        }
      }

      /**
       * Filters out all items with no `label` provided
       * @param breadcrumbs {Page[]}
       * @returns filterBreadcrumbs {Page[]}
       */
      return filter(breadcrumbs, page => !!page.label);
    },
    buildBreadcrumbs(pathConfig, breadcrumbs) {
      const childrenConfig = find(getValue(pathConfig, 'children', []), (config) => config.urlRegexp.test(this.pageUrl));

      breadcrumbs.push({
        label: pathConfig.label,
        link: first(this.pageUrl.match(pathConfig.urlRegexp)),
      });

      if (childrenConfig) {
        this.buildBreadcrumbs(childrenConfig, breadcrumbs);
      }
    },

    /**
     *  Returns the object based on the routeLabelPath
     * @param page
     * @returns {{link: *, label: *}}
     */
    interpolate(page) {
      const { path } = page;
      const label = getValue(page, 'meta.label');
      const link = this.replaceParams(path, this.routeParams);

      return { link, label };
    },
    replaceParams(path) {
      let url = path;

      each(this.routeParams, (value, key) => {
        url = url.replace(`:${key}`, `${value}`);
      });

      return url;
    },
    routeLabel(route) {
      return route && route.label;
    },
    getIsMatchingUrl(link) {
      return link === this.pageUrl;
    },
    isLastItem(index) {
      return index === size(this.getBreadcrumbs()) - 1;
    },
  },
};
</script>

<template>
  <div class="BreadcrumbsComponent emobg-font-weight-semibold d-flex align-content-center justify-content-start">
    <ul class="d-flex">
      <li
        v-for="(url, index) in getBreadcrumbs()"
        :key="index"
      >
        <RouterLink
          v-if="!getIsMatchingUrl(url.link) && !isLastItem(index)"
          :to="url && url.link"
          class="emobg-link-primary emobg-body-2"
        >
          {{ routeLabel(url) }}
        </RouterLink>
        <span
          v-else
          class="emobg-color-black emobg-body-2"
        >
          {{ routeLabel(url) }}
        </span>
        <span
          v-if="!getIsMatchingUrl(url.link) && !isLastItem(index)"
          class="mr-1 emobg-color-primary"
        >/</span>
      </li>
    </ul>
  </div>
</template>
